import 'tailwindcss/tailwind.css';
import '../styles/globals.css';
import '../assets/css/custom.scss';
import React, { useEffect, useState } from 'react';
import CookieConsent, { getCookieConsentValue } from 'react-cookie-consent';
import Link from 'next/link';
import Script from 'next/script';
import { TrackContext } from '../components/track';

import 'mapbox-gl/dist/mapbox-gl.css';

function MyApp({ Component, pageProps }) {
  const [track, setTrack] = useState(false)
  const updateTracking = () => setTrack(getCookieConsentValue() === 'true');
  useEffect(() => {
    updateTracking();
  }, []);
  
  
  return (
    <>
      <TrackContext.Provider value={track}>
        <CookieConsent
          onAccept={updateTracking}
          buttonText="Accept"
          containerClasses='bg-background-card text-text-primary text-small'
          buttonStyle={{
            color: 'hsla(0, 0%, 100%, 1)',
            background: 'hsla(206, 21%, 45%, 1)',
            borderRadius: '64px',
            padding: '8px 16px',
          }}
          style={{background:'',
        color:'',}}
        >
          <span className="ml-2">
            Ilder is not tracking cookies by default. If you are okay with
            tracking, please hit accept. You can read our{' '}
            <Link href="/privacy" className="font-bold text-text-link underline hover:no-underline">
              privacy policy here
            </Link>
            .
          </span>
          
        </CookieConsent>
          {track ? (
            <>
              <Script
                strategy="lazyOnload"
                src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLEANALYTICS_PROD}`}
              />
              <Script id="google-analytics" strategy="lazyOnload">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('set', {cookie_flags: 'SameSite=None;Secure'});
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLEANALYTICS_PROD}');
                `}
              </Script>
            </>
          ) : (
            <div />
          )}
          <Component {...pageProps} />
        </TrackContext.Provider>
    </>
  );
}

export default MyApp;
